import { Button, Card, Flex, Select, Slider, Switch, Typography } from 'antd';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { useEffect, useState, useRef } from 'react';
import {
  postCustomPromptDelete,
  postCustomPromptToEdit,
} from '../../hooks/api';
import dots from '../../assets/dots.svg';
import trash from '../../assets/trash.svg';
import edit from '../../assets/edit.svg';

const { Title, Paragraph, Text } = Typography;

const CustomPrompt = ({ prompt, setRequestData, isFirst }) => {
  const [responseData, setResponseData] = useState(null);
  const [deleteResponseData, setDeleteResponseData] = useState(null);
  const [title, setTitle] = useState(prompt.title);
  const [description, setDescription] = useState(prompt.description);
  const [gradeScale, setGradeScale] = useState(prompt.gradeScale);
  const [isEnabled, setIsEnabled] = useState(prompt['isEnabled']);
  const [selectedType, setSelectedType] = useState(prompt.selectedType);
  const inputRef = useRef(null);
  const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const width = window.innerWidth;
  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      input.style.height = 'auto';
      input.style.height = `${input.scrollHeight}px`;
    }
  }, [description]);

  const textStyled = {
    fontFamily: 'Inter',
    fontSize: width < 500 ? '12px' : '14px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'left',
    color: 'rgba(128, 128, 128, 1)',
  };

  useEffect(() => {
    postCustomPromptToEdit(
      prompt.id,
      {
        title: title.trim(),
        description: description.trim(),
        gradeScale: 10,
        isEnabled: isEnabled.toString(),
        selectedType,
      },
      setResponseData
    );
  }, [title, description, gradeScale, isEnabled, selectedType]);

  return (
    <Card
      size="small"
      title={
        <input
          id={'prompt' + prompt.id}
          placeholder=""
          value={title}
          disabled={isDisabled}
          style={{
            background: 'none',
            outline: 'none',
            border: 'none',
            fontFamily: 'Inter',
            fontSize: width < 500 ? '16px' : '26.49px',
            fontWeight: '500',
            lineHeight: width < 500 ? '20px' : '32px',
            letterSpacing: '-0.2px',
            color: 'rgba(22, 22, 22, 1)',
            width: '100%',
          }}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') setIsDisabled(true);
          }}
        />
      }
      extra={
        <Flex style={{ position: 'relative' }} gap={'small'} align={'center'}>
          <Switch
            size={width < 500 ? 'small' : 'default'}
            onChange={(e) => setIsEnabled(!!e)}
            defaultValue={isEnabled}
            style={{
              backgroundColor: isEnabled
                ? 'rgba(55, 112, 255, 1)'
                : 'rgba(4, 4, 21, 0.47)',
            }}
          />
          <img
            onClick={() => {
              setIsVisibleEdit(!isVisibleEdit);
              console.log(prompt['isEnabled'], isEnabled);
            }}
            src={dots}
            alt="dots"
            width={32}
            height={32}
            style={{ cursor: 'pointer' }}
          />
          <div
            className="choice__container"
            style={{
              width: '188px',
              height: '96px',
              position: 'absolute',
              top: '45px',
              right: '0',
              display: isVisibleEdit ? 'flex' : 'none',
              flexDirection: 'column',
              border: '1px solid rgba(242, 243, 245, 1)',
              borderRadius: '8px',
              zIndex: 100,
              backgroundColor: 'rgba(255, 255, 255, 1)',
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
            }}
          >
            <div
              className="choice__item"
              style={{
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
              }}
              onClick={() => {
                setIsDisabled(false);
                document.getElementById(`prompt${prompt.id}`).focus();
                setIsVisibleEdit(false);
              }}
            >
              <img
                src={edit}
                alt="edit"
                style={{ width: '24px', height: '24px' }}
              />
              <p
                style={{
                  margin: 0,
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: 'rgba(14, 14, 14, 1)',
                }}
              >
                Редактировать
              </p>
            </div>
            <div
              className="choice__item"
              style={{
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
              }}
              onClick={() => {
                document.querySelector('.overlay').style.display = 'flex';
                document
                  .querySelector('.delete-popup')
                  .addEventListener('click', () => {
                    postCustomPromptDelete(prompt.id, setRequestData);
                    document.querySelector('.overlay').style.display = 'none';
                  });
                setIsVisibleEdit(false);
              }}
            >
              <img
                src={trash}
                alt="trash"
                style={{ width: '24px', height: '24px' }}
              />
              <p
                style={{
                  margin: 0,
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: ' rgba(14, 14, 14, 1)',
                }}
              >
                Удалить
              </p>
            </div>
          </div>
        </Flex>
      }
      style={{
        padding: '24px',
        width: width < 500 ? '100%' : 'calc(50% - 6px)',
        borderRadius: 24,
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        animation: isFirst ? 'fadeIn 0.5s ease-out forwards' : 'none',
      }}
    >
      <Flex vertical gap={'20px'} style={{ width: '100%' }}>
        <Flex
          style={{ width: '100%' }}
          vertical
          align={'flex-start'}
          gap={'8px'}
        >
          <Text style={textStyled}>Тип</Text>
          <Select
            className="custom-select"
            defaultValue={selectedType}
            style={{ width: '100%', textAlign: 'left' }}
            options={[
              { value: 'string', label: 'Строковый' },
              { value: 'number', label: 'Оценочный' },
            ]}
            onChange={(e) => setSelectedType(e)}
          />
        </Flex>
        <Flex
          vertical
          align={'flex-start'}
          gap={'8px'}
          style={{ width: '100%' }}
        >
          <Text style={textStyled}>Промпт</Text>
          <textarea
            ref={inputRef}
            style={{
              padding: width < 500 ? '8px' : '16px',
              borderRadius: '8px',
              border: '1px solid rgba(210, 210, 210, 1)',
              fontFamily: 'Arial',
              fontSize: width < 500 ? '12px' : '14px',
              fontWeight: '400',
              lineHeight: '24px',
              textAlign: 'left',
              color: 'rgba(32, 32, 32, 1)',
              width: '100%',
              height: 'auto',
              outline: 'none',
              background: 'white',
              resize: 'none',
              boxSizing: 'border-box',
            }}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') setIsDisabled(true);
            }}
            disabled={isDisabled}
          />
        </Flex>
      </Flex>
    </Card>
  );
};

export default CustomPrompt;
