import { Button, Card, Modal, Skeleton, Typography } from 'antd';
import { useState } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';

const { Text } = Typography;

const min = (v1, v2) => (v1 < v2 ? v1 : v2);

const makeEllipsis = (text, count) => {
  if (text.length <= count) {
    return (
      <Text
        style={{
          display: '-webkit-box',
          WebkitLineClamp: 10,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxHeight: '115px',
          padding: '0px',
        }}
      >
        {text}
      </Text>
    );
  }
  return (
    <Text
      style={{
        display: '-webkit-box',
        WebkitLineClamp: 10,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxHeight: '115px',
        padding: '0px',
      }}
    >
      {text.slice(0, count)} <EllipsisOutlined style={{ color: '#a8a8a8' }} />
    </Text>
  );
};

const TextModal = (props) => {
  const { isModalOpen, setIsModalOpen, title, text } = props;

  return (
    <Modal
      title={title}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      onOk={() => setIsModalOpen(false)}
      width={'95vw'}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      okText={'OK'}
    >
      <Text>{text}</Text>
    </Modal>
  );
};

const TextCard = (props) => {
  const width = window.innerWidth;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLoaded, data, textType, title } = props;

  return (
    <Card
      extra={
        isLoaded && (
          <Button
            type={'link'}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            View
          </Button>
        )
      }
      title={title}
      bordered={false}
      style={{
        width:
          width < 500
            ? '100%'
            : title === 'Transcription' || title === 'Summary'
            ? 'calc(50% - 6px)'
            : 'calc(33% - 8px)',
        height:
          width < 500
            ? 'auto'
            : title === 'Transcription' || title === 'Summary'
            ? '204px'
            : 'auto',
        padding: width < 500 ? 24 : 12,
        gap: width < 500 ? '8px' : '16px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 24,
        textAlign: 'left',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '22px',
        letterSpacing: '-0.2px',
        overflow: 'hidden',
      }}
    >
      {isLoaded && data && data[textType] ? (
        makeEllipsis(data[textType], 1024)
      ) : (
        <Skeleton active />
      )}
      <TextModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={title}
        text={isLoaded && data && data[textType] ? data[textType] : null}
      />
    </Card>
  );
};

export default TextCard;
