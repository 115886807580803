import {
  postCustomPromptCreate,
  useCustomPrompts,
  useUser,
} from '../../hooks/api';
import { Alert, Button, Flex, Typography, Menu, Divider } from 'antd';
import { useEffect, useState } from 'react';
import { apiURL } from '../../config';
import CustomPrompt from '../../components/lkcards/customprompt';
import plus from '../../assets/plus.svg';

const { Title, Text } = Typography;

const PromptsPage = () => {
  const baseurl = apiURL;
  const [requestData, setRequestData] = useState(null);
  const [user, isUserLoading, userError] = useUser([requestData]);
  const [customPrompts, customPromptsLoading, customPromptsError] =
    useCustomPrompts([requestData]);
  const [newPromptId, setNewPromptId] = useState(null);
  const [isAnimationEnabled, setIsAnimationEnabled] = useState(false);

  useEffect(() => {
    if (customPrompts && customPrompts.length > 0) {
      const lastPrompt = customPrompts[0];

      setNewPromptId(lastPrompt.id);

      console.log(lastPrompt.id);
    }
  }, [customPrompts]);
  const width = window.innerWidth;

  const titleStyled = {
    fontFamily: 'Inter',
    fontSize: width < 500 ? '22px' : '40px',
    fontWeight: '600',
    lineHeight: width < 500 ? '40px' : '52px',
    textAlign: 'left',
    color: 'rgba(22, 22, 22, 1)',
    margin: 0,
  };
  return (
    <Flex
      vertical
      gap={'24px'}
      style={{ width: '100%', padding: '8px 32px 60px', flexGrow: 2 }}
      align={'flex-start'}
      justify={'flex-start'}
    >
      <Flex
        vertical={false}
        justify="space-between"
        align="center"
        style={{ width: '100%' }}
      >
        <Title style={{ ...titleStyled, color: 'white' }} level={1}>
          Промпты
        </Title>
        <button
          className="prompt-button"
          onClick={() => {
            postCustomPromptCreate(setRequestData);
            setIsAnimationEnabled(true);
          }}
        >
          <img
            src={plus}
            alt="plus"
            style={{
              width: width < 500 ? '20px' : '24px',
              height: width < 500 ? '20px' : '24px',
            }}
          />
          <p
            style={{
              margin: 0,
              color: 'white',
              fontFamily: 'Inter',
              fontSize: width < 500 ? '11px' : '16px',
              fontWeight: '400',
              lineHeight: width < 500 ? '22px' : '24px',
              letterSpacing: '-0.2px',
              textAlign: 'center',
            }}
          >
            Добавить кастомный промт
          </p>
        </button>
      </Flex>
      <Flex
        vertical={width < 500}
        wrap
        gap={'12px'}
        justify={'center'}
        style={{ width: '100%' }}
      >
        {customPrompts &&
          customPrompts.map((prompt) => (
            <CustomPrompt
              key={prompt.id}
              setRequestData={setRequestData}
              prompt={prompt}
              isFirst={prompt.id === newPromptId && isAnimationEnabled}
            />
          ))}
      </Flex>
    </Flex>
  );
};

export default PromptsPage;
