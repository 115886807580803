import { Typography } from 'antd';

const { Text } = Typography;

const ShowTries = ({ tries }) => {
  const triesColors = {
    5: '#50e678',
    4: '#e6a550',
    3: '#e6a550',
    2: '#e6a550',
    1: '#e6a550',
    0: '#e65550',
    '∞': '#50e678',
  };

  const showTries = tries === -1000 ? '∞' : tries;

  return (
    <Text
      style={{
        textAlign: 'left',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '19.2px',
        letterSpacing: '-0.3px',
        color: 'rgba(85, 85, 85, 1)',
      }}
    >
      Осталось тестовых попыток:{' '}
      <Text
        style={{
          color: triesColors[showTries],
          textAlign: 'left',
          fontFamily: 'Inter',
          fontSize: '16px',
          lineHeight: '19.2px',
          letterSpacing: '-0.3px',
        }}
      >
        {showTries}
      </Text>
    </Text>
  );
};

export default ShowTries;
