import { Input, Button, Typography, Flex, Form } from 'antd';
import { useContext, useEffect, useState } from 'react';
import {
  postLogin,
  postVerify,
  postCheckAccount,
  postSignIn,
  postRefreshPassword,
  postNewPassword,
  useYandex,
} from '../hooks/api';
import { MessageContext } from '../pages/App/App';
import { apiURL } from '../config';
import mainlogo from '../assets/mainlogo.svg';

// import authlogo from '../../assets/authlogo.png';

import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const { Search, OTP } = Input;
const { Text, Title } = Typography;

const labelStyle = {
  fontFamily: 'SB Sans Text',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '-0.3px',
  textAlign: 'left',
};

const btnStyle = {
  width: '100%',
  height: '44px',
  padding: '10px 16px 10px 16px',
  borderRadius: '6px',
  textAlign: 'center',
  background: 'linear-gradient(91.05deg, #00D1FF -0.03%, #661AFF 100.08%)',
  color: 'white',
  fontFamily: 'SB Sans Text',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '-0.3px',
  textAlign: 'center',
  outline: 'none',
  borderColor: 'white',
};

const width = window.innerWidth;
const formStyle = {
  maxWidth: '684px',
  width: '100%',
  padding: '32px 24px 32px 24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  borderRadius: width < 500 ? '20px 20px 0 0' : '20px',
  background: 'rgba(255, 255, 255, 1)',
  boxShadow: '0px 8px 16px 0px rgba(29, 39, 115, 0.25)',
};

const EnterPhone = ({ onButtonClick, goTo }) => {
  return (
    <Form
      name="basic"
      style={formStyle}
      layout={'vertical'}
      align={'center'}
      onFinish={(e) => {
        onButtonClick(e.email.toLowerCase(), e.phone, e.password);
      }}
      onFinishFailed={(e) => console.log(e)}
      autoComplete="off"
      requiredMark={false}
    >
      <Flex vertical align={'center'} gap={24} style={{ width: '100%' }}>
        <Title
          style={{
            fontFamily: 'Inter',
            fontSize: '22px',
            fontWeight: 600,
            lineHeight: '28.23px',
            textAlign: 'center',
            margin: '0',
            color: 'rgba(51, 63, 72, 1)',
          }}
          level={3}
        >
          Регистрация
        </Title>
        <Form.Item
          label={<span style={labelStyle}>Почта</span>}
          name="email"
          style={{ width: '100%', marginBottom: '0' }}
          rules={[
            {
              required: true,
              message: 'Введите почту',
            },
            {
              pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
              message: 'Введите корректную почту',
            },
          ]}
        >
          <Input
            placeholder="john@example.org"
            style={{ width: '100%' }}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={<span style={labelStyle}>Номер телефона</span>}
          name="phone"
          style={{ width: '100%', marginBottom: '0' }}
          rules={[
            {
              required: true,
              message: 'Введите номер телефона',
            },
            {
              pattern: /^(\+?\d{1,3}[- ]?)?\d{10}$/,
              message: 'Введите корректный номер телефона',
            },
          ]}
        >
          <Input
            placeholder="+79998887766"
            style={{ width: '100%' }}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label={<span style={labelStyle}>Пароль</span>}
          name="password"
          style={{ width: '100%', marginBottom: '0' }}
          rules={[
            {
              required: true,
              message: 'Введите пароль',
            },
            {
              pattern:
                '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d!@#$%^&*()_+={}:;"\'<>,.?~`-]{6,}$',
              message:
                'Пароль должен содержать минимум 6 символов, включая буквы и цифры.',
            },
          ]}
        >
          <Input.Password placeholder="" style={{ width: '100%' }} allowClear />
        </Form.Item>
        <Form.Item
          style={{ width: '100%', marginBottom: '0' }}
          label={<span style={labelStyle}>Пароль еще раз</span>}
          name="passwordagain"
          rules={[
            {
              required: true,
              message: 'Введите пароль',
            },
            {
              pattern:
                '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d!@#$%^&*()_+={}:;"\'<>,.?~`-]{6,}$',
              message:
                'Пароль должен содержать минимум 6 символов, включая буквы и цифры.',
            },

            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Пароли не совпадают'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="" style={{ width: '100%' }} allowClear />
        </Form.Item>

        <Button autoFocus htmlType={'submit'} type={'primary'} style={btnStyle}>
          Отправить код
        </Button>
        <Button type="text" onClick={() => goTo('sign-in')}>
          Уже есть аккаунт?
        </Button>
      </Flex>
    </Form>
  );
};
const SignInComponent = ({ onButtonClick, goTo, yandex }) => {
  return (
    <Form
      name="basic"
      style={formStyle}
      layout={'vertical'}
      align={'center'}
      onFinish={(e) => {
        onButtonClick(e.email.toLowerCase(), e.password);
      }}
      requiredMark={false}
      onFinishFailed={(e) => console.log(e)}
      autoComplete="off"
    >
      <Flex vertical gap={'middle'} align={'center'} style={{ width: '100%' }}>
        <Title
          style={{
            fontFamily: 'Inter',
            fontSize: '22px',
            fontWeight: 600,
            lineHeight: '28.23px',
            textAlign: 'center',
            margin: '0',
            color: 'rgba(51, 63, 72, 1)',
          }}
          level={3}
        >
          Вход
        </Title>
        <Form.Item
          label={<span style={labelStyle}>Почта</span>}
          name="email"
          style={{ width: '100%', marginBottom: '0' }}
          rules={[
            {
              required: true,
              message: 'Введите почту',
            },
            {
              pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
              message: 'Введите корректную почту',
            },
          ]}
        >
          <Input
            placeholder="john@example.org"
            style={{ width: '100%' }}
            allowClear
          />
        </Form.Item>
        <Form.Item
          style={{ width: '100%', marginBottom: '0' }}
          label={<span style={labelStyle}>Пароль</span>}
          name="password"
          rules={[
            {
              required: true,
              message: 'Введите пароль',
            },
            {
              pattern:
                '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d!@#$%^&*()_+={}:;"\'<>,.?~`-]{6,}$',
              message:
                'Пароль должен содержать минимум 6 символов, включая буквы и цифры.',
            },
          ]}
        >
          <Input.Password placeholder="" style={{ width: '100%' }} allowClear />
        </Form.Item>
        <Button autoFocus htmlType={'submit'} type={'primary'} style={btnStyle}>
          Войти
        </Button>
        <Button type="text" onClick={() => goTo('password')}>
          Сброс пароля
        </Button>
        <Button type="text" onClick={() => goTo('phone')}>
          Зарегистроваться
        </Button>
        <Button type="text" onClick={yandex}>
          Войти через Яндекс.ID
        </Button>
      </Flex>
    </Form>
  );
};

const RefreshPasswordComponent = ({ onButtonClick, goTo }) => {
  return (
    <Form
      name="basic"
      style={formStyle}
      layout={'vertical'}
      align={'center'}
      onFinish={(e) => {
        onButtonClick(e.email.toLowerCase(), window.location.href);
      }}
      requiredMark={false}
      onFinishFailed={(e) => console.log(e)}
      autoComplete="off"
    >
      <Flex vertical gap={'middle'} align={'center'} style={{ width: '100%' }}>
        <Title
          style={{
            fontFamily: 'Inter',
            fontSize: '22px',
            fontWeight: 600,
            lineHeight: '28.23px',
            textAlign: 'center',
            margin: '0',
            color: 'rgba(51, 63, 72, 1)',
          }}
          level={3}
        >
          Восстановление пароля
        </Title>
        <Form.Item
          label={<span style={labelStyle}>Почта</span>}
          name="email"
          style={{ width: '100%', marginBottom: '0' }}
          rules={[
            {
              required: true,
              message: 'Введите почту',
            },
            {
              pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
              message: 'Введите корректную почту',
            },
          ]}
        >
          <Input
            placeholder="john@example.org"
            style={{ width: '100%' }}
            allowClear
          />
        </Form.Item>
        <Button autoFocus htmlType={'submit'} type={'primary'} style={btnStyle}>
          Отправить письмо
        </Button>
        <Button type="text" onClick={() => goTo('sign-in')}>
          Выполнить вход?
        </Button>
      </Flex>
    </Form>
  );
};

const EnterOTP = ({ onButtonClick }) => {
  const width = window.innerWidth;
  const [otp, setOtp] = useState('');
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (otp.length !== 6) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [otp]);

  const btnDisabledStyle = {
    background: `${
      disabled
        ? 'rgba(164, 164, 164, 0.4);'
        : 'linear-gradient(91.05deg, #00D1FF -0.03%, #661AFF 100.08%)'
    }`,
    color: `${disabled ? 'rgba(85, 85, 85, 1);' : 'white'}`,
  };

  return (
    <Flex vertical gap={'middle'} align={'start'} style={formStyle}>
      <Title
        strong
        style={{
          fontFamily: 'Inter',
          fontSize: '22px',
          fontWeight: 600,
          lineHeight: '28.23px',
          textAlign: 'left',
          margin: '0',
          color: 'rgba(51, 63, 72, 1)',
        }}
        level={3}
      >
        Введите код верификации, отправленный на вашу почту
      </Title>
      <Input.OTP
        onChange={(e) => setOtp(e)}
        formatter={(str) => str.toUpperCase()}
      />
      <Button
        style={{ marginTop: '8px', ...btnStyle, ...btnDisabledStyle }}
        type={'primary'}
        onClick={() => onButtonClick(otp)}
        disabled={disabled}
      >
        Подтвердить
      </Button>
    </Flex>
  );
};

const NewPasswordComponent = ({ onButtonClick }) => {
  return (
    <Form
      name="basic"
      style={formStyle}
      layout={'vertical'}
      align={'center'}
      onFinish={(e) => {
        onButtonClick(e.password);
      }}
      onFinishFailed={(e) => console.log(e)}
      autoComplete="off"
      requiredMark={false}
    >
      <Flex vertical align={'center'} gap={24} style={{ width: '100%' }}>
        <Title
          style={{
            fontFamily: 'Inter',
            fontSize: '22px',
            fontWeight: 600,
            lineHeight: '28.23px',
            textAlign: 'center',
            margin: '0',
            color: 'rgba(51, 63, 72, 1)',
          }}
          level={3}
        >
          Восстановление пароля
        </Title>
        <Form.Item
          label={<span style={labelStyle}>Новый пароль</span>}
          name="password"
          style={{ width: '100%', marginBottom: '0' }}
          rules={[
            {
              required: true,
              message: 'Введите пароль',
            },
            {
              pattern: '^[A-Za-z0-9_\\-@\\.]+$',
              message: 'Разрешены только символы a-z, 0-9, _, -, @, .',
            },
          ]}
        >
          <Input.Password placeholder="" style={{ width: '100%' }} allowClear />
        </Form.Item>
        <Form.Item
          style={{ width: '100%', marginBottom: '0' }}
          label={<span style={labelStyle}>Новый пароль еще раз</span>}
          name="passwordagain"
          rules={[
            {
              required: true,
              message: 'Введите пароль',
            },
            {
              pattern: '^[A-Za-z0-9_\\-@\\.]+$',
              message: 'Разрешены только символы a-z, 0-9, _, -, @, .',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Пароли не совпадают'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="" style={{ width: '100%' }} allowClear />
        </Form.Item>

        <Button autoFocus htmlType={'submit'} type={'primary'} style={btnStyle}>
          Поменять пароль
        </Button>
      </Flex>
    </Form>
  );
};

const Auth = ({ setUserDidLogin }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const uid = queryParams.get('uid');
  const token = queryParams.get('token');
  const [currentStage, setCurrentStage] = useState(uid ? 'refresh' : 'phone');
  const [verifyResponse, setVerifyResponse] = useState(null);
  const [password, setPassword] = useState(null);

  const [refreshResponse, setRerfeshResponse] = useState(null);
  const [loginResponse, setLoginResponse] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [showError, showSuccess] = useContext(MessageContext);

  useEffect(() => {
    if (verifyResponse && verifyResponse.status === 'error') {
      showError(verifyResponse.message);
    } else if (verifyResponse && verifyResponse.status === 'success') {
      showSuccess(verifyResponse.message);
      setCurrentStage('otp');
    }
  }, [verifyResponse, setVerifyResponse]);

  useEffect(() => {
    if (password && password.status === 'error') {
      showError(password.message);
    } else if (password && password.status === 'success') {
      showSuccess(password.message);
      setCurrentStage('sign-in');
    }
  }, [password, setPassword]);

  useEffect(() => {
    if (refreshResponse && refreshResponse.status === 'error') {
      showError(refreshResponse.message);
    } else if (refreshResponse && refreshResponse.status === 'success') {
      showSuccess(refreshResponse.message);
      setCurrentStage('sign-in');
    }
  }, [refreshResponse, setRerfeshResponse]);

  useEffect(() => {
    if (loginResponse && loginResponse.status === 'error') {
      showError(loginResponse.message);
    } else if (loginResponse && loginResponse.status === 'success') {
      setUserDidLogin(true);
      showSuccess(loginResponse.message);
    }
  }, [loginResponse, setLoginResponse]);

  const onPhoneButtonClick = async (email, phone, password) => {
    await postCheckAccount(email, setVerifyResponse);
    if (verifyResponse && verifyResponse.status === 'error') {
      return;
    } else {
      postVerify(email, phone, password, setVerifyResponse);
      setUserEmail(email);
    }
  };

  const onOTPButtonClick = (otp) => {
    postLogin(userEmail, otp, setLoginResponse);
    navigate('/');
  };
  const onSignInButtonClick = (email, password) => {
    postSignIn(email, password, setLoginResponse);
    setUserEmail(email);
    navigate('/');
  };

  const onRefreshBtnClick = (email, url) => {
    postRefreshPassword(email, url, setRerfeshResponse);
  };

  const onYandexSign = () => {
    const clientId = '88007aa4d5a843da95ae49772122482c';
    const redirectUri = `${window.location.origin}/auth/callback`;
    const yandexAuthUrl = `https://oauth.yandex.ru/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;

    window.location.href = yandexAuthUrl;
  };

  const onNewPasswordBtnClick = (new_password) => {
    postNewPassword(uid, new_password, token, setPassword);
    navigate('/');
  };
  const goTo = (stage) => {
    setCurrentStage(stage);
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px',
        height: '100vh',
        width: '100vw',
      }}
    >
      <img
        style={{ marginTop: width < 500 ? 'auto' : 'none' }}
        src={mainlogo}
        alt="logo"
      />
      {currentStage === 'phone' && (
        <EnterPhone onButtonClick={onPhoneButtonClick} goTo={goTo} />
      )}
      {currentStage === 'sign-in' && (
        <SignInComponent
          onButtonClick={onSignInButtonClick}
          goTo={goTo}
          yandex={onYandexSign}
        />
      )}
      {currentStage === 'password' && (
        <RefreshPasswordComponent
          onButtonClick={onRefreshBtnClick}
          goTo={goTo}
        />
      )}
      {currentStage === 'refresh' && (
        <NewPasswordComponent onButtonClick={onNewPasswordBtnClick} />
      )}
      {currentStage === 'otp' && <EnterOTP onButtonClick={onOTPButtonClick} />}
    </div>
  );
};

export default Auth;
