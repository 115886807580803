import { useEffect, useState } from 'react';
import axios from 'axios';
import { apiURL } from '../config';

export const useApi = (url, request_data = {}, deps = []) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    axios
      .get(apiURL + url, { params: request_data })
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  }, [...deps]);

  return [data, isLoading, error];
};

export const useReport = (taskId, deps = []) =>
  useApi('/check_result/' + taskId, {}, deps);
export const useYandex = (deps = []) => useApi('/login/yandex', {}, deps);
export const useUser = (deps = []) => useApi('/user', {}, deps);
export const useCustomPrompts = (deps = []) =>
  useApi('/my/custom_prompts', {}, deps);
export const useAiRequests = (page, step, deps = []) =>
  useApi('/my/ai_requests', { page, step }, deps);
export const useUserToEdit = (email, deps = []) =>
  useApi('/user/' + email, {}, deps);

export const postApi = (
  url,
  data,
  setData = (e) => {},
  setError = (e) => {},
  reload = false
) => {
  axios
    .post(apiURL + url, data)
    .then((res) => {
      console.log(res.data);
      setData(res.data);

      return res.data;
    })
    .catch((err) => {
      console.log(err.message);
      setError(err.message);

      return err.message;
    });
};

export const postVerify = (email, phone, password, setData) =>
  postApi('/user/verify', { phone, email, password }, setData, setData);

export const postCheckAccount = (email, setData) =>
  postApi('/user/check/account', { email }, setData, setData);

export const postRefreshPassword = (email, link, setData) =>
  postApi('/user/refresh/password', { email, link }, setData, setData);

export const postNewPassword = (uid, password, token, setData) =>
  postApi('/user/new/password', { uid, password, token }, setData, setData);

export const postSignIn = (email, password, setData) =>
  postApi('/user/signin', { email, password }, setData, setData);

export const postUserToEdit = (email, e, setData) =>
  postApi('/user/' + email, e, setData, setData);

export const postCustomPromptToEdit = (id, e, setData) =>
  postApi('/my/custom_prompts/' + id, e, setData, setData);

export const postCustomPromptDelete = (id, setData) =>
  postApi('/my/custom_prompts/' + id + '/delete', {}, setData, setData);

export const postCustomPromptCreate = (setData) =>
  postApi('/my/custom_prompts/create', {}, setData, setData);

export const postLogin = (email, otp, setData) =>
  postApi('/login', { email, otp }, setData, setData);

export const postRenewToken = (setData) =>
  postApi('/user/token/renew', {}, setData, setData);
