import { Badge, Flex, Pagination, Table, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useAiRequests } from '../../hooks/api';
import { useState } from 'react';

const { Text } = Typography;

const BillingTable = () => {
  const [paginationData, setPaginationData] = useState({ page: 1, step: 10 });
  const [aiRequests, aiRequestsLoading, aiRequestsError] = useAiRequests(
    paginationData.page,
    paginationData.step,
    [paginationData]
  );

  const columns = [
    {
      title: 'Идентификатор запроса',
      dataIndex: 'uuid',
      key: 'uuid',
    },
    {
      title: 'Время',
      dataIndex: 'fetched_at',
      key: 'fetched_at',
    },
    {
      title: 'Потрачено минут',
      dataIndex: 'minutes',
      key: 'minutes',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (status, { description }) => {
        if (status === 'SUCCESS') {
          return (
            <Flex gap={'small'}>
              <Badge status={'success'} />
              <Text>SUCCESS</Text>
            </Flex>
          );
        } else if (status === 'PROGRESS') {
          return (
            <Flex gap={'small'}>
              <Badge status={'processing'} />
              <Text>PROGRESS</Text>
            </Flex>
          );
        } else if (status === 'PENDING') {
          return (
            <Flex gap={'small'}>
              <Badge status={'processing'} />
              <Text>PENDING</Text>
            </Flex>
          );
        } else if (status === 'PREFAILED') {
          return (
            <Flex gap={'small'}>
              <Badge status={'error'} />
              <Text>PREFAILURE</Text>
              <Tooltip title={description}>
                <InfoCircleOutlined />
              </Tooltip>
            </Flex>
          );
        } else if (status === 'FAILURE') {
          return (
            <Flex gap={'small'}>
              <Badge status={'error'} />
              <Text>FAILURE</Text>
            </Flex>
          );
        }
      },
    },
  ];

  const dataSource =
    aiRequests && aiRequests.data
      ? aiRequests.data.map((aiRequest) => ({
          ...aiRequest,
        }))
      : [];

  return (
    <Flex vertical gap={'small'} style={{ width: '100%' }} align={'center'}>
      <Table
        columns={columns}
        dataSource={dataSource}
        style={{ width: '100%' }}
        pagination={false}
        scroll={{ x: 500 }} // Добавляем горизонтальный скролл для маленьких экранов
      />
      {aiRequests && (
        <Pagination
          defaultCurrent={paginationData.page}
          total={aiRequests.total}
          onChange={(page, step) => {
            setPaginationData({ page, step });
          }}
        />
      )}
    </Flex>
  );
};

export default BillingTable;
