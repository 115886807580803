import { Button, Flex, Typography, Progress } from 'antd';
import { useEffect, useState, useRef } from 'react';
import ImportAudio from '../../components/uploader';
import ShowInfo from '../../components/showinfo';
import { useReport, useUser } from '../../hooks/api';
import Auth from '../../components/auth';
import ShowTries from '../../components/showtries';
import AdminPanel from '../../components/admin/adminpanel';
import Loading from '../../components/loading';
import HeaderComponent from '../../components/header';
import download from '../../assets/download.svg';
import robot from '../../assets/robot.svg';
import folder from '../../assets/folder.svg';
import trash from '../../assets/trash.svg';
import warning from '../../assets/warning.svg';
import success from '../../assets/success.svg';

const { Text, Title } = Typography;

const cardStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  background: 'white',
  borderRadius: '24px',
};

const DemoPage = () => {
  const width = window.innerWidth;
  const textStyled = {
    fontFamily: 'Inter',
    fontSize: width < 500 ? '16px' : '24px',
    fontWeight: '500',
    lineHeight: width < 500 ? '26px' : '32px',
    textAlign: 'left',
    color: 'rgba(85, 85, 85, 1)',
    margin: 0,
  };

  const titleStyled = {
    fontFamily: 'Inter',
    fontSize: width < 500 ? '30px' : '40px',
    fontWeight: '600',
    lineHeight: width < 500 ? '40px' : '52px',
    textAlign: 'left',
    color: 'rgba(22, 22, 22, 1)',
    margin: 0,
  };

  const [uuid, setUuid] = useState(null);
  const [showUuid, setShowUuid] = useState(null);
  const [mustShowInfo, setMustShowInfo] = useState(false);
  const [doUpdate, setDoUpdate] = useState(0);
  const [progress, setProgress] = useState(0);
  const [data, isLoading, error] = useReport(uuid, [uuid, progress]);
  const [userDidLogin, setUserDidLogin] = useState(false);
  const [showData, setShowData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, isUserLoading, userError] = useUser([
    userDidLogin,
    setUserDidLogin,
    uuid,
    setUuid,
  ]);
  const [filename, setFilename] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [isFailed, setIsFailed] = useState(false);

  useEffect(() => {
    if (uuid) {
      setShowUuid(uuid);
      setMustShowInfo(true);
      const intervalId = setInterval(() => {
        setProgress((prevProgress) => {
          setDoUpdate(prevProgress); // Trigger the report fetch
          if (prevProgress >= 100) {
            clearInterval(intervalId);
          }
          if (prevProgress >= 90) {
            return 10;
          }
          return prevProgress + 3; // Increment progress
        });
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [uuid]);

  useEffect(() => {
    if (data && data.status === 'SUCCESS') {
      setMustShowInfo(true);
      setShowData(data.result);
      setIsLoaded(true);
      setUuid(null);
      setProgress(100);
      setIsFailed(false);
    } else if (data && data.status === 'FAILURE') {
      setMustShowInfo(true);
      setShowData(data.result);
      setIsLoaded(true);
      setUuid(null);
      setProgress(80);
      setIsFailed(true);
    }
  }, [data]);

  if (user && user.isAnonymous) {
    return <Auth setUserDidLogin={setUserDidLogin} />;
  } else if (user) {
    return (
      <>
        <HeaderComponent />

        <Flex
          vertical
          gap={'52px'}
          align={'flex-start'}
          justify={'flex-start'}
          style={{ padding: '8px 32px 60px', flexGrow: 2 }}
        >
          <div
            style={{
              ...cardStyle,
              gap: '24px',
              padding: width < 500 ? '20px' : '32px',
              width: '100%',
            }}
          >
            <Flex vertical gap={'4px'} align="flex-start">
              <Title style={titleStyled} level={1}>
                Обработка аудио
              </Title>

              <p style={textStyled}>
                Загрузите аудио-файл для дальнейшей обработки
              </p>
            </Flex>

            <ImportAudio
              setUuid={setUuid}
              setFilename={setFilename}
              setFileSize={setFileSize}
              disabled={(!user.isAdmin && user.triesLeft <= 0) || filename}
            />

            {fileSize && filename && (
              <Flex
                vertical={false}
                align="center"
                gap={'12px'}
                style={{
                  padding: '12px 16px',
                  border: '1px solid rgba(219, 219, 219, 1)',
                  width: width < 500 ? '90%' : '508px',
                  borderRadius: '12px',
                  margin: '0 auto',
                }}
              >
                {!isLoaded && (
                  <div className="conic-gradient-donut-loader">
                    <div className="circle"></div>
                  </div>
                )}
                {isFailed && (
                  <Flex style={{ width: '24px', height: '24px' }}>
                    <img src={warning} alt="warning" />
                  </Flex>
                )}

                {isLoaded && !isFailed && (
                  <Flex style={{ width: '24px', height: '24px' }}>
                    <img src={success} alt="success" />
                  </Flex>
                )}

                <img
                  src={folder}
                  alt="folder"
                  style={{ width: '24px', height: '24px' }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '352px',
                    gap: '4px',
                  }}
                >
                  <p
                    style={{
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: '500',
                      lineHeight: '20px',
                      textAlign: 'left',
                      margin: 0,
                      color: 'rgba(28, 28, 28, 1)',
                    }}
                  >
                    {filename}
                  </p>
                  <p
                    style={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: '400',
                      lineHeight: '16px',
                      textAlign: 'left',
                      margin: 0,
                      color: isFailed
                        ? 'rgba(219, 55, 31, 1)'
                        : 'rgba(85, 85, 85, 1)',
                    }}
                  >
                    {isFailed
                      ? 'Upload Failed. Please Try Again'
                      : `${parseFloat(fileSize).toFixed(1)} MB`}
                  </p>
                </div>
                <img
                  src={trash}
                  onClick={() => {
                    setFilename('');
                    setFileSize('');
                    // ручка для отмены загрузки
                  }}
                  alt="trash"
                  style={{ width: '40px', height: '40px', cursor: 'pointer' }}
                />
              </Flex>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: width < 500 ? 'column' : 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: width < 500 ? 'flex-start' : 'center',
                gap: width < 500 ? '5px' : 'auto',
              }}
            >
              <ShowTries tries={user.triesLeft} />

              <Button
                type={'link'}
                href={
                  'https://drive.usercontent.google.com/u/0/uc?id=1BoXX6s0thbG6S52lO32hYq6PlGGRbyxJ&export=download'
                }
                style={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '19.2px',
                  letterSpacing: '-0.3px',
                  color: 'rgba(55, 112, 255, 1)',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  alignItems: 'center',
                  padding: width < 500 ? 0 : 'auto',
                }}
              >
                Скачать тестовый аудио-файл
                <img
                  src={download}
                  alt="download"
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              </Button>
            </div>
          </div>
          <Flex vertical gap={'24px'} style={{ width: '100%' }}>
            <Flex
              vertical={width < 500}
              justify="space-between"
              align="baseline"
              gap={width < 500 ? '5px' : 'auto'}
              style={{ width: '100%' }}
            >
              <Title style={{ ...titleStyled, color: 'white' }} level={2}>
                Расшифровка аудио
              </Title>
              <a
                style={{
                  color: 'rgba(0, 148, 255, 1)',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '19.2px',
                  color: 'white',
                  textAlign: 'left',
                }}
                href={'https://mmca_support.t.me'}
                target="_blank"
              >
                В случае возниковения проблем - обращайтесь в поддержку
              </a>
            </Flex>
            {!filename && (
              <div
                style={{
                  ...cardStyle,
                  gap: '16px',
                  padding: width < 500 ? '171px 20px 171px' : '171px 0 171px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <img
                  alt="roboto"
                  src={robot}
                  style={{ width: '80px', height: '80px' }}
                />
                <p
                  style={{
                    fontFamily: 'Inter',
                    fontSize: width < 500 ? '20px' : '26px',
                    fontWeight: '500',
                    lineHeight: width < 500 ? '26px' : '32px',
                    textAlign: 'center',
                    margin: 0,
                    color: 'rgba(22, 22, 22, 1)',
                  }}
                >
                  Здесь будет расшифровка аудио
                </p>
                <p
                  style={{
                    fontFamily: 'Inter',
                    fontSize: width < 500 ? '20px' : '26px',
                    fontWeight: '500',
                    lineHeight: width < 500 ? '26px' : '32px',
                    textAlign: 'center',
                    color: 'rgba(85, 85, 85, 1)',
                    margin: 0,
                  }}
                >
                  Мы дадим Вам обратную связь и советы для работы оператора
                </p>
              </div>
            )}
            {filename && <ShowInfo data={showData} isLoaded={isLoaded} />}
          </Flex>
        </Flex>
      </>
    );
  } else {
    return (
      <>
        <HeaderComponent />
        <Flex
          vertical
          gap={'middle'}
          align={'center'}
          justify={'flex-start'}
          style={{ height: '100vh', paddingBottom: '60px' }}
        >
          <Title style={titleStyled} level={1}>
            Обработка аудио
          </Title>
          <Loading />
        </Flex>
      </>
    );
  }
};

export default DemoPage;
