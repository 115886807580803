import { Button, Card, Flex, Popconfirm } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import copy from '../../assets/copy-double.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ApiDataCard = ({
  title,
  data,
  renewFunc = null,
  setRequestData = null,
}) => {
  const width = window.innerWidth;
  return (
    <Card
      size="small"
      title={title}
      style={{
        padding: '24px',
        width: width < 500 ? '100%' : 'calc(50% - 6px)',
        borderRadius: 24,
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      <Flex vertical justify={'center'} gap={'16px'} align={'flex-start'}>
        <Flex
          vertical={false}
          justify="space-between"
          align="center"
          style={{ width: '100%' }}
        >
          <p
            style={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '20px',
              textAlign: 'left',
              color: 'rgba(128, 128, 128, 1)',
              margin: 0,
            }}
          >
            Описание
          </p>
          {renewFunc && (
            <Popconfirm
              okText={'Да'}
              cancelText={'Нет'}
              title={'Заменить токен'}
              description={
                'Вы уверены, что ' +
                'хотите заменить токен? В таком случае, ' +
                'старый токен перестанет действовать.'
              }
              onConfirm={() => {
                renewFunc(setRequestData);
              }}
            >
              <Button
                style={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: 'rgba(55, 112, 255, 1)',
                  height: 'auto',
                  padding: 0,
                }}
                type={'link'}
              >
                Заменить
              </Button>
            </Popconfirm>
          )}
        </Flex>

        <Flex
          vertical={false}
          justify="space-between"
          align="center"
          style={{
            width: '100%',
            border: '1px solid rgba(210, 210, 210, 1)',
            padding: '16px',
          }}
        >
          <p
            style={{
              margin: 0,
              fontFamily: 'Arial',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '24px',
              textAlign: 'left',
            }}
          >
            {data}
          </p>
          <CopyToClipboard
            text={data}
            onCopy={(text, result) => console.log(text, result)}
          >
            <img
              src={copy}
              alt="copy"
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
            />
          </CopyToClipboard>
        </Flex>
      </Flex>
    </Card>
  );
};

export default ApiDataCard;
