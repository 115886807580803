import { Button, Card, Flex, Typography } from 'antd';
import copy from '../../assets/copy-double-blue.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const { Text, Paragraph } = Typography;
const ApiEndpointCard = ({ title, endpoint }) => {
  const width = window.innerWidth;
  const textStyled = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'left',
    color: 'rgba(128, 128, 128, 1)',
    margin: 0,
  };
  const arr = [
    {
      name: endpoint.method,
      value: endpoint.uri,
    },
    {
      name: 'Описание',
      value: endpoint.description,
    },
    {
      name: 'Хэдеры',
      value: endpoint.header,
    },
    {
      name: 'Пример ввода',
      value: endpoint.inputExample,
    },
    {
      name: 'Пример вывода',
      value: endpoint.outputExample,
    },
  ];
  return (
    <Card
      size="small"
      title={title}
      extra={
        <CopyToClipboard
          text={endpoint.curl}
          onCopy={(text, result) => console.log(text, result)}
        >
          <Button
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: '6px',
              height: 'auto',
              padding: 0,
            }}
            type={'link'}
            // onClick={() => {
            //   try {
            //     // Выполняем команду копирования
            //     const successful = document.execCommand('copy');
            //     const message = successful ? 'successful' : 'unsuccessful';
            //     console.log('Copy command was ' + message);
            //   } catch (err) {
            //     console.error('Unable to copy', err);
            //   }
            //   navigator.clipboard.writeText(endpoint.curl);
            // }}
          >
            <img
              src={copy}
              alt="copy"
              style={{ width: '24px', height: '24px' }}
            />
            <p
              style={{
                fontFamily: 'Inter',
                fontSize: '18px',
                fontWeight: '500',
                lineHeight: '28px',
                color: 'rgba(55, 112, 255, 1)',
                margin: 0,
              }}
            >
              CURL
            </p>
          </Button>
        </CopyToClipboard>
      }
      style={{
        padding: '24px',
        width: width < 500 ? '100%' : 'calc(50% - 6px)',
        borderRadius: 24,
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Flex
        vertical
        justify={'flex-start'}
        align={'flex-start'}
        gap={'20px'}
        style={{ width: '100%' }}
      >
        {arr.map((item, index) => (
          <Flex
            vertical
            gap={'8px'}
            align="flex-start"
            style={{ width: '100%' }}
            justify="flex-start"
            key={index}
          >
            <Text style={textStyled}>{item.name}</Text>
            <Flex
              vertical={false}
              justify="space-between"
              align="center"
              style={{
                width: '100%',
                border: '1px solid rgba(210, 210, 210, 1)',
                padding: '16px',
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontFamily: 'Arial',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '24px',
                  textAlign: 'left',
                }}
              >
                {item.value}
              </p>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Card>
  );
};

export default ApiEndpointCard;
