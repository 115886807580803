import { Layout } from 'antd';
import mainlogo from '../assets/mainlogo.svg';

import { useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { apiURL } from '../config';

const { Header } = Layout;

const HeaderComponent = () => {
  const navigate = useNavigate();
  const data = [
    {
      id: 1,
      name: 'Главная',
      route: '/main',
    },
    {
      id: 2,
      name: 'Демо',
      route: '/',
    },
    {
      id: 3,
      name: 'API',
      route: '/data_api',
    },
    {
      id: 4,
      name: 'Промпты',
      route: '/prompts',
    },
    {
      id: 5,
      name: 'Выйти из ЛК',
      route: '/',
    },
  ];
  const location = useLocation();
  const [isActive, setIsActive] = useState(
    data.find((item) => item.route === location.pathname)?.id ?? 2
  );
  const logout = () => {
    axios
      .get(apiURL + '/logout')
      .then((res) => {
        console.log(res.data);
        window.location.href = '/';
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleNavigation = (id, route) => {
    navigate(route);
    setIsActive(id);
  };
  const width = window.innerWidth;
  return (
    <Layout style={{ background: 'transparent', minHeight: 'auto', flex: 1 }}>
      <Header
        style={{
          display: 'flex',
          flexDirection: width < 500 ? 'column' : 'row',
          justifyContent: 'space-between',
          gap: width < 500 ? '20px' : 'auto',
          alignItems: 'center',
          padding: width < 500 ? '32px' : '32px 40px 32px 32px',
          background: 'transparent',
          height: 'auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <img height={28} src={mainlogo} alt="logo1" />
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: width < 500 ? 'center' : 'flex-end',
            flexWrap: width < 500 ? 'wrap' : 'nowrap',
            gap: '20px',
          }}
        >
          {data.map((item) => (
            <div
              style={{
                fontFamily: 'Inter',
                fontSize: '18px',
                fontWeight: '500',
                lineHeight: '28px',
                color:
                  isActive === item.id ? 'rgba(255, 255, 255, 0.6)' : 'white',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (item.id === 5) {
                  logout();
                } else {
                  handleNavigation(item.id, item.route);
                }
              }}
              key={item.id}
            >
              {item.name}
            </div>
          ))}
          {/* <img
            style={{
              height: '40px',
              width: '40px',
              marginRight: '16px',
            }}
            src={avatar}
            alt="avatar"
          />
          <button
            onClick={logout}
            style={{
              background: 'transparent',
              border: 'none',
              width: '20px',
              height: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <img src={exit} alt="exit" />
          </button> */}
        </div>
      </Header>
    </Layout>
  );
};
export default HeaderComponent;
