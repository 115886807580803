import { postRenewToken, useUser } from '../../hooks/api';
import { Flex, Typography } from 'antd';
import ApiDataCard from '../../components/lkcards/apidatacard';
import { useEffect, useState } from 'react';
import { apiURL } from '../../config';
import ApiEndpointCard from '../../components/lkcards/apiendpointcard';

const { Title, Text } = Typography;

const ApiPage = () => {
  const width = window.innerWidth;
  const baseurl = apiURL;
  const [requestData, setRequestData] = useState(null);
  const [user, isUserLoading, userError] = useUser([requestData]);

  const titleStyled = {
    fontFamily: 'Inter',
    fontSize: width < 500 ? '30px' : '40px',
    fontWeight: '600',
    lineHeight: width < 500 ? '40px' : '52px',
    textAlign: 'left',
    color: 'rgba(22, 22, 22, 1)',
    margin: 0,
  };

  const ai_analyze_audio_endpoint = {
    description:
      'Эта ручка получает ваш токен доступа и аудио файл записи звонка, запускает анализ и ' +
      'возвращает ID задачи (' +
      'им можно воспользоваться для получения результата анализа)',
    method: 'POST',
    curl:
      'curl -F "file=@test.mp3" -H "Content-Type: multipart/form-data" -H "Authorization:' +
      user?.token +
      '" -X POST ' +
      baseurl +
      '/analyze_audio',
    baseurl: baseurl,
    uri: '/analyze_audio',
    header: 'Authorization ' + user?.token,
    inputExample: '{\n' + '    "file": string(binary),\n' + '}',
    outputExample:
      '{\n' + '  "task_id": "dd98578d-167c-4a29-9d1d-6f9e7e1798f4",\n' + '}',
  };
  const check_result_endpoint = {
    description:
      'Эта ручка получает ID задачи как параметр ссылки и возвращает результат анализа записи звонка',
    method: 'GET',
    baseurl: baseurl,
    curl:
      'curl -H "Authorization:' +
      user?.token +
      '" -X GET ' +
      baseurl +
      '/check_result/<task_id>',
    uri: '/check_result/{task_id}',
    header: 'Authorization ' + user?.token,
    inputExample: '{}',
    outputExample:
      '{\n' +
      '  "transcription": "",\n' +
      '  "summary": "",\n' +
      '  "agent_feedback": "",\n' +
      '  "concise_advice": "",\n' +
      '  "communication_skills": 6,\n' +
      '  "sales_technique": 5,\n' +
      '  "general": 6,\n' +
      '  "user_satisfaction_index": 6,\n' +
      '  "agent_performance": 5.5,\n' +
      '  "status": "SUCCESS"\n' +
      '}',
  };
  return (
    <Flex
      vertical
      gap={'56px'}
      style={{
        width: '100%',
        padding: '8px 32px 60px',
        flexGrow: 2,
      }}
      align={'flex-start'}
      justify={'flex-start'}
    >
      <Flex
        vertical
        align="flex-start"
        gap={width < 500 ? '15px' : '25px'}
        style={{ width: '100%' }}
      >
        <Title style={{ ...titleStyled, color: 'white' }} level={1}>
          Данные для API
        </Title>
        <Flex
          vertical={width < 500}
          justify="space-between"
          gap={width < 500 ? '10px' : 'auto'}
          align="flex-start"
          style={{ width: '100%' }}
        >
          <ApiDataCard title={'Base URL'} data={baseurl} />
          <ApiDataCard
            title={'Token'}
            renewFunc={postRenewToken}
            setRequestData={setRequestData}
            data={user?.token}
          />
        </Flex>
      </Flex>
      <Flex
        vertical
        align="flex-start"
        gap={width < 500 ? '15px' : '25px'}
        style={{ width: '100%' }}
      >
        <Title style={{ ...titleStyled, color: 'white' }} level={1}>
          API Эндпоинты
        </Title>
        <Flex
          vertical={width < 500}
          justify="space-between"
          align="flex-start"
          gap={width < 500 ? '10px' : 'auto'}
          style={{ width: '100%' }}
        >
          <ApiEndpointCard
            title={'Analyze Audio'}
            endpoint={ai_analyze_audio_endpoint}
          />
          <ApiEndpointCard
            title={'Check Result'}
            endpoint={check_result_endpoint}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ApiPage;
