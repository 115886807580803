import { Card, Flex, Rate, Skeleton, Typography, Spin } from 'antd';
import TextCard from './TextCard';
import Rating from './rate';
import { LoadingOutlined } from '@ant-design/icons';

const { Text } = Typography;

const ShowInfo = (props) => {
  const { data, isLoaded, uuid } = props;
  const width = window.innerWidth;

  const textStyled = {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '22px',
    letterSpacing: '-0.2px',
    textAlign: 'center',
  };

  return (
    <Flex
      vertical={width < 500}
      align={'flex-start'}
      gap={'12px'}
      wrap
      style={{ width: '100%', marginTop: '24px' }}
    >
      <TextCard
        data={data}
        isLoaded={isLoaded}
        textType={'transcription'}
        title={'Transcription'}
      />
      <TextCard
        data={data}
        isLoaded={isLoaded}
        textType={'summary'}
        title={'Summary'}
      />
      <Card
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: width < 500 ? '8px' : '16px',
          justifyContent: 'center',
          width: width < 500 ? '100%' : 'calc(33% - 8px)',
          padding: width < 500 ? 24 : 12,
          borderRadius: 24,
        }}
        title="Rate"
      >
        <Flex vertical gap={width < 500 ? '12px' : '24px'} align={'center'}>
          <Flex vertical gap={'8px'} align={'center'}>
            <Text style={textStyled}>General</Text>
            <Rating
              data={data}
              isLoaded={isLoaded}
              textType={'general_assessment'}
            />
          </Flex>
          <Flex vertical gap={'8px'} align={'center'}>
            <Text style={textStyled}>Agent performance</Text>
            <Rating
              data={data}
              isLoaded={isLoaded}
              textType={'agent_performance'}
            />
          </Flex>
          <Flex vertical gap={'8px'} align={'center'}>
            <Text style={textStyled}>Communication skills</Text>
            <Rating
              data={data}
              isLoaded={isLoaded}
              textType={'communication_skills_assessment'}
            />
          </Flex>
          <Flex vertical gap={'8px'} align={'center'}>
            <Text style={textStyled}>Sales technique</Text>
            <Rating
              data={data}
              isLoaded={isLoaded}
              textType={'sales_technique_assessment'}
            />
          </Flex>
          <Flex vertical gap={'8px'} align={'center'}>
            <Text style={textStyled}>User satisfaction</Text>
            <Rating
              data={data}
              isLoaded={isLoaded}
              textType={'user_satisfaction_assessment'}
            />
          </Flex>
        </Flex>
      </Card>
      <TextCard
        data={data}
        isLoaded={isLoaded}
        textType={'errors'}
        title={'Feedback'}
      />
      <TextCard
        data={data}
        isLoaded={isLoaded}
        textType={'tips'}
        title={'Advice'}
      />
    </Flex>
  );
};

export default ShowInfo;
