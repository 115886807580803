import { Card, Flex, Progress, Statistic } from 'antd';

const BalanceCard = ({ balance }) => {
  const percentage =
    balance && balance.total !== 0 ? (balance.left / balance.total) * 100 : 0;
  const width = window.innerWidth;
  return (
    <Card
      size="small"
      title="Баланс"
      style={{
        padding: '24px',
        width: width < 500 ? '100%' : 'calc(50% - 6px)',
        borderRadius: 24,
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      <Flex vertical gap={'16px'} align={'flex-start'} justify="center">
        <p
          style={{
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '22px',
            letterSpacing: '-0.2px',
            textAlign: 'left',
            color: 'rgba(85, 85, 85, 1)',
            margin: 0,
          }}
        >
          Осталось минут
        </p>
        <p
          style={{
            fontFamily: 'Inter',
            fontSize: '40px',
            fontWeight: '500',
            lineHeight: '52px',
            letterSpacing: '-1px',
            textAlign: 'center',
            color: 'rgba(85, 85, 85, 1)',
            margin: 0,
            color: 'rgba(22, 22, 22, 1)',
            width: '100%',
          }}
        >
          {balance?.left ?? 0} / {balance?.total ?? 0}
        </p>
        <Progress
          percent={percentage}
          strokeColor="rgba(55, 112, 255, 1)"
          showInfo={false}
        />
      </Flex>
    </Card>
  );
};

export default BalanceCard;
