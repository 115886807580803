import { useUser } from '../../hooks/api';
import { Flex, Typography, Divider, Card } from 'antd';
import BalanceCard from '../../components/lkcards/balancecard';
import { useEffect, useState } from 'react';
import BillingTable from '../../components/lkcomps/billingtable';

const { Title, Text } = Typography;

const MainPage = () => {
  const width = window.innerWidth;
  const titleStyled = {
    fontFamily: 'Inter',
    fontSize: width < 500 ? '30px' : '40px',
    fontWeight: '600',
    lineHeight: width < 500 ? '40px' : '52px',
    textAlign: 'left',
    color: 'rgba(22, 22, 22, 1)',
    margin: 0,
  };
  const [requestData, setRequestData] = useState(null);
  const [user, isUserLoading, userError] = useUser([requestData]);
  return (
    <Flex
      vertical
      gap={'75px'}
      style={{ width: '100%', padding: '8px 32px 60px', flexGrow: 2 }}
      align={'flex-start'}
      justify={'flex-start'}
    >
      <Flex
        vertical
        align="flex-start"
        gap={width < 500 ? '15px' : '40px'}
        style={{ width: '100%' }}
      >
        <Title style={{ ...titleStyled, color: 'white' }} level={1}>
          Информация
        </Title>
        <Flex
          vertical={width < 500}
          justify="space-between"
          align="center"
          gap={width < 500 ? '10px' : 'auto'}
          style={{ width: '100%' }}
        >
          <BalanceCard
            balance={{ left: user?.minutesLeft, total: user?.minutesTotal }}
          />
          <Card
            size="small"
            title="Поможем сориентироваться"
            style={{
              padding: '24px',
              width: width < 500 ? '100%' : 'calc(50% - 6px)',
              borderRadius: 24,
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              height: '221px',
              background: 'rgba(230, 230, 231, 1)',
            }}
          >
            <Flex vertical align={'flex-start'} justify="space-between">
              <p
                style={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '22px',
                  letterSpacing: '-0.2px',
                  textAlign: 'left',
                  color: 'rgba(85, 85, 85, 1)',
                  margin: 0,
                }}
              >
                Обратитесь к менеджеру для выбора и активации тарифа.
              </p>
              <button
                style={{
                  background: 'rgba(55, 112, 255, 1)',
                  color: 'white',
                  border: 'none',
                  borderRadius: '80px',
                  padding: '12px 16px',
                  cursor: 'pointer',
                  outline: 'none',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '24px',
                  letterSpacing: '-0.2px',
                  textAlign: 'center',
                  marginTop: width < 500 ? '37px' : '60px',
                }}
                onClick={() => {
                  window.open('https://mmca_support.t.me', '_blank');
                }}
              >
                Написать менеджеру
              </button>
            </Flex>
          </Card>
        </Flex>
      </Flex>
      <Flex
        vertical
        align="flex-start"
        gap={width < 500 ? '10px' : '24px'}
        style={{ width: '100%' }}
      >
        <Title style={{ ...titleStyled, color: 'white' }} level={1}>
          Запросы
        </Title>
        <BillingTable />
      </Flex>
    </Flex>
  );
};

export default MainPage;
